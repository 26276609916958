<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">

            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">New Item</h4>
              </div>
              <div class="back-btn">
                <router-link :to="{ name: 'items' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{title}}</span>
              </div>
            </div>
            <div class="create_items_section">
              <form enctype="multipart/form-data" @submit.prevent="createItems('save')">
                <div class="row">
                  <!--Left Col-->
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <label>Title</label>
                        <div>
                          <input class="form-control" v-model="title" @blur="v$.title.$touch" />
                        </div>
                        <div class="input_error" v-if="v$.title.$error">Title is required.</div>
                      </div>
                      <div v-if="showIfParent">
                        <div class="form-group">
                          <label>Description(optional)</label>
                          <div>
                            <editor v-model="description" :init="{
                              height: 300,
                              branding: false
                            }" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END Title and Desc-->
                    <!-- images test -->
                    <div id="foodie-loading-cover" class="pl-5 pr-4 bd-b">
                      <h2 class="section-title">Media</h2>
                      <div data-baseweb="form-control" class="al dw">
                        <div class="image_div">
                          <div class="img_preview" style="">
                            <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                              <img class="image_responsive" v-if="images" :src="images" />
                            </div>
                          </div>
                          <div class="image_content">
                            <p>File requirement: JPG, PNG, GIF, or WEBP up to 3MB.
                              Minimum pixels required: 320 for width and height.</p>

                            <div class="image_button">

                              <div v-if="images" :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-1']">
                                <div class="btn_plus_icon">
                                  <i class="fa fa-minus" style="font-size:24px" @click="removeImage()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Remove Image</span>
                                </div>
                              </div>

                              <div :class="['add_image_btn_grp', {'item_btn_add_grp': images}, 'mt-2']">
                                <div class="btn_plus_icon">
                                  <input id='fileid' type='file' @change="uploadImage($event, 1000, 1000, 'item')" hidden />
                                  <i class="fa fa-plus" style="font-size:24px" @click="openFileUploadDialog()"></i>
                                </div>
                                <div class="btn_url">
                                  <span>Add from URL</span>
                                </div>
                              </div>
                            </div>

                            <!-- <div class="image_button">
                              <input type="file" style="border:none;" accept="image/*"
                                @change="uploadImage($event, 1000, 1000, 'item')" id="file-input"
                                class="form-control" />
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div id="foodie-loader"> </div>
                    </div>
                    <!--END Photo-->
                    <div class="pl-5 pr-4 bd-b">
                      <h2 class="section-title">Pricing</h2>
                      <div class="form-group" v-if="!appConfigs.allow_multiprice">
                        <label>Price</label>
                        <div>
                          <input class="form-control" v-model="price" @blur="v$.price.$touch" name="price" />
                        </div>
                        <div class="input_error" v-if="v$.price.$error">
                          <span v-if="v$.price.required.$invalid">price is required</span>
                          <span v-if="v$.price.numeric.$invalid">price cannot be a string</span>
                        </div>

                      </div>

                      <div class="form-group" v-else>
                        <label>Price</label>
                        <div class="mb-1">
                          <small class="text-muted">price is set per third-party vendor (channel)</small>
                        </div>
                        <div class="mt-2">
                          <div class="row">
                            <div class="col-md-4 col-sm-6 col-xm-6">
                              FOODIE
                            </div>
                            <div class="col-md-8 col-sm-6 col-xm-6">
                              <div class="form-group">
                                <input type="text" class="form-control" v-model="itemChannelPrice[0]" :placeholder="`Enter FOODIE Price`">
                              </div>

                            </div>
                          </div>
                          <div class="row" v-for="channelData in channelSettings" :key="channelData.id">
                            <div class="col-md-4">
                              {{ channelData.channel_name }}
                            </div>
                            <div class="col-md-8 col-sm-6 col-xm-6">
                              <div class="form-group">
                                <input type="text" class="form-control" name="" v-model="itemChannelPrice[channelData.id]" id="" :placeholder="`Enter ${channelData.channel_name} Price`">
                              </div>

                            </div>
                          </div>

                        </div>

                      </div>

                      <div class="mt-3 form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label>Tax Rate</label>
                            <div>
                              <input class="form-control" v-model="taxRate" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label>Vat Rate Percentage</label>
                            <input class="form-control" v-model="vat" name="pos_id" />
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--End Pricing Section-->
                    <div v-if="showIfParent" class="pl-5 pr-4 pt-4 bd-b">

                      <h2 class="section-title">Customize Item</h2>
                      <small class="text-muted">Modifier groups allow customers to use toppings, sides and more to
                        customize items</small><br />
                      <div class="customise_item">
                      </div>
                      <div class="form-group mt-4">
                        <button type="button" data-toggle="modal" data-target="#modifier_group" class="btn btn-default custom-orange"><i class="fa fa-plus mr-2"></i> Add Modifier
                          Group</button>
                      </div>
                      <div class="mod_scrollable" v-if="modifiersGrps.length > 0">
                        <div class="modifier_group">
                          <div class="row" v-for="(modifiersGrp, mindx) in modifiersGrps" :key="mindx">
                            <div class="form-group">
                              <div class="Modifier_group_header">
                                <div class="row">
                                  <div class="col-md-10">
                                    <label>{{ modifiersGrp.name }}</label>
                                    <input v-model="groupId[modifiersGrp.id]" type="hidden" class="form-control" />
                                  </div>
                                  <div class="col-md-2">
                                    <span @click="removeModifier(modifiersGrp.id)" class="remove_icon glyphicon glyphicon-remove"></span>
                                  </div>
                                </div>
                              </div>
                              <hr>
                              <div class="modifier_items mt-2">
                                <div class="row" v-for="(itm, itindex) in modifiersGrp.choices" :key="itindex">

                                  <div class="col-md-3">
                                    <span><b>Name</b></span> <br><br>
                                    <span>
                                      {{ itm.item_name }}
                                    </span>
                                    <input type="hidden" v-model="groupItemId[itm.id]">
                                  </div>
                                  <div class="col-md-5">
                                    <div class="form-group">
                                      <label>Pos ID</label>
                                      <div class="row" v-for="posconfig in posConfigs" :key="posconfig.id">
                                        <div class="col-md-3 col-sm-6 col-xm-6">
                                          <strong>{{ posconfig.name }}</strong>

                                        </div>

                                        <div class="col-md-9 col-sm-6 col-xm-6">

                                          <div class="form-group">
                                            <input class="form-control" v-model="modPosID[`${itm.id}_${posconfig.id}_${modifiersGrp.id}`]" :placeholder="`Enter ${posconfig.name} POS ID`">
                                            <!-- end mod -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- <div class="form-group">
                                      <label>Pos ID</label>
                                      <input class="form-control" v-model="groupItemPos[itm.id]">
                                    </div> -->
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label>Price</label>
                                      <div v-if="!appConfigs.allow_multiprice">
                                        <input class="form-control" v-model="groupItemPrice[itm.id]" :disabled="disabled">
                                      </div>
                                      <div v-else>
                                        <!-- <input class="form-control" v-model="groupItemPrice[itm.id]" :disabled="disabled"> -->
                                        <button type="button" class="btn btn-outline-secondary mod_price_edit" data-toggle="collapse" :data-target="`#demo_${modifiersGrp.id}_${itm.id}`">Edit</button>

                                      </div>
                                    </div>
                                  </div>


                                  <div class="col-md-12">
                                    <div :id="`demo_${modifiersGrp.id}_${itm.id}`" class="collapse mb-4 bd-b bt-b">
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xm-6" v-for="channelData in channelSettings" :key="channelData.id">
                                          <label> {{ channelData.channel_name }}</label>
                                          <div class="form-group">
                                            <input type="text" class="form-control" v-model="modItemChannelPrice[`${channelData.id}_${modifiersGrp.id}_${itm.id}`]" :placeholder="`Enter ${channelData.channel_name} Price`">
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xm-6">
                                          <label>FOODIE</label>
                                          <div class="form-group">
                                            <input type="text" class="form-control" v-model="modItemChannelPrice[`0_${modifiersGrp.id}_${itm.id}`]" :placeholder="`Enter FOODIE Price`">
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- modifier modals -->
                    <div class="modal fade" id="modifier_group" tabindex="-1" role="dialog" aria-labelledby="modifier_group" aria-hidden="true">
                      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Add Modifier Group</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="search_content mb-3">
                              <div class="row">
                                <div class="col-md-6">
                                  <input aria-invalid="false" v-model="search" @keyup="searchModifiers()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                </div>
                              </div>
                            </div>
                            <div class="scrollable_content">
                              <div class="row">
                                <div class="col-md-12">

                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Modifier Group</th>
                                        <th>Options</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(modifiergroup, mdgindex) in modifiersGroups" :key="mdgindex">
                                        <td>
                                          <input v-if="modifiergroup.choices != null" v-model="modifierGroups" :value="modifiergroup" type="checkbox">
                                          <input v-else :value="modifiergroup" type="checkbox" disabled>
                                        </td>
                                        <td>{{ modifiergroup.name }}</td>
                                        <td>
                                          <div v-if="modifiergroup.choices != null">
                                            <span v-for="(item, itimindex) in modifiergroup.choices" :key="itimindex">{{
                                                                                                                        item.item_name
                                                                                                                      }}
                                              <span v-if="itimindex + 1 < modifiergroup.choices.length">, </span>
                                            </span>
                                          </div>
                                          <div v-else>
                                            <span>-</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button type="button" data-dismiss="modal" aria-label="Close" @click="addModifierGroup" class="btn btn-primary">Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <!-- end modifier group-->

                    <div class="pl-5 pr-4 pt-4">
                      <h2 class="section-title">Item POS Details</h2>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="type">
                              <label>Select POS ID requirement</label>
                              <select class="form-control" v-model="postRequirementType">
                                <option value="1"> POS ID Required</option>
                                <option value="2" :disabled="modifiersGrps.length > 0 ? false :true"> Modifier Group Override</option>
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">

                          <div class="col-md-6">
                            <div class="pos" v-if="postRequirementType == 1 || postRequirementType == 3">
                              <!-- <label>Pos ID</label>
                              <input class="form-control" v-model="posid" name="pos_id" /> -->

                              <!-- addd modifications for multiple pos -->
                              <div class="mb-2">
                                <label>Pos ID's</label>
                              </div>

                              <hr>
                              <div class="mt-3 mb-3 pos_scrollable_content">
                                <div class="row" v-for="posconfig in posConfigs" :key="posconfig.id">
                                  <div class="col-md-3 col-sm-6 col-xm-6">
                                    <strong>{{ posconfig.name }}</strong>

                                  </div>

                                  <div class="col-md-9 col-sm-6 col-xm-6">

                                    <div class="form-group">
                                      <input class="form-control" v-model="posID[posconfig.id]" :placeholder="`Enter ${posconfig.name} POS ID`">

                                      <!-- end mod -->
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <hr>

                              <!-- end -->

                            </div>
                            <div class="modgrp" v-if="postRequirementType == 2 && modifiersGrps.length > 0">

                              <div class="mb-2">
                                <label>Select Modifier Group To override POS Id</label>
                              </div>
                              <hr>
                              <div class="mt-3">
                                <select class="form-control" v-model="modifierGroupId">
                                  <option v-for="(modifiergrp, grpindex) in modifiersGrps" :key="grpindex" :value="modifiergrp.id">{{ modifiergrp.name }}</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="mb-2">
                              <label>Cloud Pos ID's</label>
                            </div>

                            <hr>
                            <div class="mt-3 mb-3 pos_scrollable_content">
                              <div class="row" v-for="posconfig in posConfigs" :key="posconfig.id">
                                <div class="col-md-3 col-sm-6 col-xm-6">
                                  <strong>{{ posconfig.name }}</strong>

                                </div>

                                <div class="col-md-9 col-sm-6 col-xm-6">

                                  <div class="form-group">
                                    <input class="form-control" v-model="cloudPosCode[posconfig.id]" :placeholder="`Enter ${posconfig.name} POS ID`">

                                    <!-- end mod -->
                                  </div>
                                </div>
                              </div>

                            </div>
                            <hr>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Item Options</h2>
                      <div class="form-group col-md-6 pad-l-0">
                        <label>Sell item on its own?</label>
                        <label style="float:right;" class="switch">
                          <input type="checkbox" v-model="standalone" @click="configureSubProduct($event)" v-bind:true-value="true" v-bind:false-value="false" />
                          <span class="slider round"></span>
                        </label>

                      </div>
                      <div v-if="showIfParent" class="col-md-7 pad-l-0">
                        <div class="form-group">
                          <label>Category</label>
                          <div>
                            <Multiselect v-model="categoryOptions" mode="tags" placeholder="Add Categories" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="options">
                              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user">
                                  {{ option.name }}
                                  <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                  </span>
                                </div>
                              </template>
                            </Multiselect>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--END Category -->
                    <div class="pl-4 pr-5 bd-b">
                      <div class="form-group">

                        <div data-toggle="collapse" aria-controls="operating_hours" @click="changeIcon">
                          <div class="row ">
                            <div class="col-md-12">
                              <div class="section_title">
                                <h2 class="section-title">Manage Availability</h2>
                                <!-- <span v-html="availabilityIcon"></span> -->
                              </div>

                            </div>
                            <div class="col-md-12 mb-4">
                              <p> <small>
                                Select Stores to include this item to set availability and offer of an item per selected
                                store
                              </small></p>
                            </div>
                          </div>

                        </div>
                        <div id="manage_availability">
                          <div class="row mb-3">
                            <div class="col-md-4 col-sm-4 col-xm-4">
                              <input v-if="adminRoles.includes(user.role.id)" id="check_all" type="checkbox" @click="checkAll()" class="mr-2"><label>Store To Include</label>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xm-4">
                              <label>Available in stock </label>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xm-4">
                              <label>Permanently out of stock </label>
                            </div>
                          </div>
                          <hr>
                          <div class="mt-3 scrollable_content" v-if="adminRoles.includes(user.role.id)">
                            <div class="row" v-for="store in stores" :key="store.id">
                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <div class="form-group">
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="checkbox" v-model="strs[store.id]" :id="'store_' + store.id" @click="changeAvailabilityStatusPerStore(store.id)" class="form-check-input" :value="store.id"> {{ store.branch_name }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <div class="form-group">
                                  <label class="switch">
                                    <input type="checkbox" :id="'availability_' + store.id" @click="checkAvailability(store.id)" v-model="isAvailable[store.id]" v-bind:true-value="true" v-bind:false-value="false" />
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>

                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <!-- <div class="form-group">
                                  <label class="switch">
                                    <input type="checkbox" v-model="onOffer[store.id]" v-bind:true-value="true" v-bind:false-value="false" />
                                    <span class="slider round"></span>
                                  </label>
                                </div> -->
                                <div class="form-group">
                                  <div class="btn-group statusgroup">
                                    <button type='button' class="btn btn-sm custom_btn btn-default">
                                      <span v-if="stockPeriod[store.id]">
                                        <i class='fa fa-check' style="color:green;"></i>
                                      </span>
                                      <span v-else>
                                        <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                    <button v-if="adminRoles.includes(user.role.id)" type="button" class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown" :id="'dropdownMenuButton'+store.id">
                                      <span class="caret"></span>
                                      <span class="sr-only">Toggle Dropdown</span>
                                    </button>

                                    <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+store.id" :disabled="isAvailable[store.id]">
                                      <a class="dropdown-item" @click="assignAvailabilityPeriod(store.id,true)" href="#">Yes</a>
                                      <a class="dropdown-item" @click="assignAvailabilityPeriod(store.id,false)" href="#">No</a>
                                    </div>
                                  </div>
                                  <!-- end mod -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 scrollable_content" v-if="user.role.id == 3">
                            <div class="row" v-for="store in managerStores" :key="store.id">
                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <div class="form-group">
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="checkbox" v-model="strs[store.id]" :id="'store_' + store.id" @click="changeAvailabilityStatusPerStore(store.id)" class="form-check-input" :value="store.id"> {{ store.branch_name }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <div class="form-group">
                                  <label class="switch">
                                    <input type="checkbox" :id="'availability_' + store.id" v-model="isAvailable[store.id]" @click="checkAvailability(store.id)" v-bind:true-value="true" v-bind:false-value="false" />
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>

                              <div class="col-md-4 col-sm-4 col-xm-4">
                                <div class="form-group">
                                  <div class="btn-group statusgroup">
                                    <button type='button' class="btn btn-sm custom_btn btn-default">
                                      <span v-if="stockPeriod[store.id]">
                                        <i class='fa fa-check' style="color:green;"></i>
                                      </span>
                                      <span v-else>
                                        <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                    <button v-if="adminRoles.includes(user.role.id)" type="button" class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown" :id="'dropdownMenuButton'+store.id" :disabled="isAvailable[store.id]">
                                      <span class="caret"></span>
                                      <span class="sr-only">Toggle Dropdown</span>
                                    </button>

                                    <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+store.id">
                                      <a class="dropdown-item" @click="assignAvailabilityPeriod(store.id,true)" href="#">Yes</a>
                                      <a class="dropdown-item" @click="assignAvailabilityPeriod(store.id,false)" href="#">No</a>
                                    </div>
                                  </div>
                                  <!-- end mod -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Is recommended? </label>
                        <label style="float:right;" class="switch">
                          <input type="checkbox" v-model="recomended" :true-value="true" :false-value="false" />
                          <span class="slider round"></span>
                        </label>
                      </div>

                      <div class="form-group">
                        <label>Menu type </label>

                        <label style="float:right;" class="radio-inline"><input type="radio" v-model="menuType" :value="1" checked>&nbsp; Online </label> &nbsp;&nbsp;
                        <label style="float:right;" class="radio-inline"><input type="radio" v-model="menuType" :value="0"> &nbsp; Physical</label>

                      </div>

                      <div class="form-group">
                        <label>Is Item Alcoholic? </label>
                        <label style="float:right;" class="switch">
                          <input type="checkbox" v-model="isAlcoholic" :true-value="true" :false-value="false" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group" v-if="isAlcoholic">
                          <label>Alcoholic Content</label>
                          <div>
                            <input class="form-control" v-model="alcoholiContent" />
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--Manage Availability -->
                    <div class="pl-4 pr-5 bd-b">
                      <div class="form-group">
                        <h2 class="section-title">Dietary attributes</h2>
                        <div>
                          <span :class="['badge mr-2',{'badge_review':selectedIcons.includes(icon.id)},{'badge_white': !selectedIcons.includes(icon.id)}]" v-for="icon in allergenicons" :key="icon.id">
                            <input type="checkbox" :id="`icon_check_${icon.id}`" :value="icon.id" @click="selectIcons(icon.id)">
                                                    &nbsp;
                            {{icon.name}}
                            <span v-if="icon.image_url">
                              <img style="border-radius:3px;vertical-align: middle;" width="35" height="35" :src="icon.image_url" alt=""> &nbsp;
                            </span>&nbsp;&nbsp;
                          </span>

                          <!-- <div class="radio-inline" v-for="icon in allergenicons" :key="icon.id">
                            <input type="checkbox" id="vegeterian" class="checkbox-custom"  value="1" />
                            <label class="checkbox-custom-label">
                              &nbsp;&nbsp;
                              <span v-if="icon.image_url">
                                <img style="border-radius:3px;vertical-align: middle;" width="20" height="20" :src="icon.image_url" alt=""> &nbsp;
                              </span>

                              {{icon.name}}

                            </label>
                          </div> -->

                          <!--
                          <div class="radio-inline">
                            <input type="checkbox" id="vegeterian" class="checkbox-custom" v-model="vegetarion" value="1" />
                            <label class="checkbox-custom-label">Vegetarian</label>
                          </div> -->
                          <!-- <div class="radio-inline">
                            <input type="checkbox" id="vegan" class="checkbox-custom" v-model="vegan" value="1" />
                            <label class="checkbox-custom-label">Vegan</label>
                          </div>
                          <div class="radio-inline">
                            <input type="checkbox" id="vegan" class="checkbox-custom" v-model="gluten" value="1" />
                            <label class="checkbox-custom-label">Gluten</label>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="related_items pl-4 pr-5  pt-4  bd-b">
                      <h2 class="section-title">Related Items</h2>
                      <div class="row">
                        <!-- div for each item -->
                        <div class="col-md-3 m-1" v-for="product in products" :key="product.id">
                          <div class="item_card h-100">
                            <div :style="{'background-image': 'url(' + product.image_url + ')'}">
                              <span class="float-right remove_icon" @click="removeItem(product)" aria-hidden="true">&times;</span>
                            </div>
                            <div class="item_card_body mt-2">
                              <h4><b>{{product.name}}</b></h4>
                              <p>{{currency +' '+ product.price}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4 mb-4">
                        <div class="col-md-12">
                          <button type="button" data-toggle="modal" data-target="#productM" class="btn custom-orange mr-3">Add New</button>

                          <!-- modal -->
                          <div class="modal fade" id="productM" tabindex="-1" role="dialog" aria-labelledby="productM" aria-hidden="true">
                            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                              <div class="modal-content">

                                <div class="">

                                  <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Related Items</h5>
                                        <button type="button" id="productM_close" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <input aria-invalid="false" v-model="itemSearch" @keyup="searchItems()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search" class="form-control">
                                      </div>

                                    </div>
                                  </div>
                                  <hr>
                                  <div class="scrollable_content pr-3 pl-3 pt-0 pb-0">
                                    <div :class="['item_list',{'disabled_content': itemLoading}]">
                                      <div v-for="item in items" :key="item.menu_item.id" class="menu_item_list">
                                        <label class="checkbox-inline">
                                          <input type="checkbox" @click="selectItems(item)" :id="`item_${item.menu_item.id}`">&nbsp; <img src="" alt=""> &nbsp; {{item.menu_item.item_name}}
                                        </label>
                                      </div>
                                      <div v-if="totalPages > 1">
                                        <Pagination :total-pages="totalPages" :per-page="itemsize" :current-page="itemPage" @pagechanged="onItemPageChange" />
                                      </div>

                                    </div>

                                  </div>
                                </div>
                                <hr>
                                <div class="pb-4 pt-4 pr-3 pl-3">

                                  <div class="row">
                                    <div class="col-md-9"></div>
                                    <div class="col-md-3 ">
                                      <div class="offer_btn_grp">
                                        <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-dgrey mr-2">cancel</button>
                                        <button type="button" data-dismiss="modal" :class="['btn',itemSelected ? 'btn-orange' : 'btn-light']" @click="addSelectedItems()">Add</button>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end -->

                        </div>
                      </div>

                    </div>

                    <!-- combos sections -->

                    <div class="related_items pl-4 pr-5  pt-4">
                      <h2 class="section-title">Combos</h2>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group col-md-6 pad-l-0">
                            <label>is item a combo</label>
                            <label style="float:right;" class="switch">
                              <input type="checkbox" v-model="isCombo" v-bind:true-value="true" v-bind:false-value="false" />
                              <span class="slider round"></span>
                            </label>

                          </div>
                        </div>
                      </div>
                      <div v-if="isCombo">

                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-6">
                              <label>Combo ID:</label>
                              <div>
                                <input class="form-control" v-model="comboID" />
                              </div>

                            </div>
                          </div>

                        </div>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="type">
                                <div class="mb-2">
                                  <label>Choose Select Group Type</label>
                                </div>

                                <select class="form-control" v-model="selectGroupType">
                                  <option value="1">Single Item</option>
                                  <option value="2" :disabled="modifiersGrps.length > 0 ? false :true"> Item Group</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="form-group">

                          <div class="row">

                            <div class="col-md-6">
                              <div class="modgrp" v-if="selectGroupType == 1">
                                <label> Enter Combo Select Item ID</label>
                                <input class="form-control" v-model="selectItem" />
                              </div>
                              <div class="modgrp" v-else>
                                <label> Base Select Group</label>
                                <select class="form-control" v-model="selectGroup">
                                  <option value="">--Select Base Select Group--</option>
                                  <option v-for="(modifiergrp, grpindex) in modifiersGroups" :key="grpindex" :value="modifiergrp.id">{{ modifiergrp.name }}</option>
                                </select>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div v-if="combosSides.length > 0">
                          <h2 class="section-title">Sides</h2>
                          <hr />
                          <div class="row">
                            <!-- div for each item -->
                            <!-- {{ combosSides }} -->
                            <div class="col-md-3 m-1" v-for="side in combosSides" :key="side.pos_id">
                              <div class="item_card h-100">
                                <div>
                                  <span class="float-right remove_icon" @click="removeSide(side.key)" aria-hidden="true">&times;</span>
                                </div>
                                <div class="item_card_body mt-2">
                                  <h4>Name: <b>{{side.name}}</b></h4>
                                  <p><b>Price: </b> {{currency +' '+ side.amount}}</p>
                                  <p> <b>Pos ID: </b> {{ side.pos_id }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-4 mb-4">
                          <div class="col-md-12">
                            <button type="button" data-toggle="modal" data-target="#sideisItemM" class="btn custom-orange mr-3">Add New Sides</button>
                            <!-- modal -->
                            <div class="modal fade" id="sideisItemM" tabindex="-1" role="dialog" aria-labelledby="productM" aria-hidden="true">
                              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div class="modal-content">

                                  <div class="">

                                    <div class="search_content pt-0 pb-0 pr-3 pl-3 mb-4">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <h5 class="modal-title" id="exampleModalLongTitle">New Sides</h5>
                                          <button type="button" id="productM_close" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                    <hr>
                                    <div class="scrollable_content pr-3 pl-3 pt-0 pb-0">
                                      <div class="form-group mt-1">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <label>Sides Name:</label>
                                            <div>
                                              <input class="form-control" v-model="sname" />
                                            </div>

                                          </div>
                                        </div>

                                      </div>

                                      <div class="form-group ">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <label>Pos ID:</label>
                                            <div>
                                              <input class="form-control" v-model="sposId" />
                                            </div>

                                          </div>
                                        </div>

                                      </div>

                                      <div class="form-group ">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <label>Quantity:</label>
                                            <div>
                                              <input class="form-control" v-model="squantity" />
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <div class="form-group ">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <label>Amount:</label>
                                            <div>
                                              <input class="form-control" v-model="samount" />
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                  <hr>
                                  <div class="pb-4 pt-4 pr-3 pl-3">

                                    <div class="row">
                                      <div class="col-md-9"></div>
                                      <div class="col-md-3 ">
                                        <div class="offer_btn_grp">
                                          <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-dgrey mr-2">cancel</button>
                                          <button type="button" data-dismiss="modal" class="btn btn-orange" @click="addSides()">Add</button>
                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end -->

                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- end combos -->

                  </div>
                </div>

                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <div class="form-group">
                        <button type="submit" class="btn btn-orange mr-3"><span v-if="spinner == 'save'" v-html="searchspinner"></span> <span v-else>Save</span></button>
                        <button type="button" @click="createItems('save&close')" class="btn custom-orange mr-3"><span v-if="spinner == 'save&close'" v-html="searchspinner"></span> <span v-else>Save &
                          Close</span></button>
                        <button type="button" @click="createItems('save&new')" class="btn btn-brown mr-3"><span v-if="spinner == 'save&new'" v-html="searchspinner"></span> <span v-else>Save &
                          New</span></button>
                        <router-link :to="{ name: 'items' }">
                          <button class="btn btn-dgrey  mr-3">Close</button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

              </form>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import { required, numeric } from '@vuelidate/validators';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import { useToast } from "vue-toastification";
import Pagination from '@/pages/pagination/pagination.vue';
const toast = useToast();

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Multiselect,
        Editor,
        Pagination
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving...",
            categoryOptions: [],
            spinner: null,
            options: [],
            boptions: [],
            branches: [],
            images: null,
            ichecked: true,
            categories: [],
            modifierGroupId: '',
            postRequirementType: 1,
            selectGroupType: 1,
            showAcoholic: false,
            showIfParent: true,
            modifierGroups: [],
            modifiersGrps: [],
            modifierGroupItems: [],
            modifiersGroups: [],
            title: '',
            description: '',
            price: '',
            tax: 0,
            posid: "",
            dietaryAtr: null,
            recomended: false,
            isAvailable: [],
            stockPeriod: [],
            standalone: true,
            onOffer: [],
            groupId: [],
            vegan: 0,
            vegetarion: 0,
            gluten: 0,
            isAlcoholic: false,
            alcoholiContent: 0,
            vat: 0,
            taxRate: 0,
            groupItemId: [],
            groupItemPrice: [],
            groupItemPos: [],
            imageUrl: '',
            itemTypeErr: '',
            search: '',
            coptions: [],
            thirdparties: [],
            stores: [],
            strs: [],
            branchCust: [],
            managerStores: [],
            itemLoading: false,
            items: [],
            itemPage: 1,
            totalPages: 0,
            itemsize: 20,
            posConfigs: [],
            posID: [],
            modPosID: [],
            totalRecords: 0,
            itemSearch: '',
            debounce: null,
            itemSelected: false,
            products: [],
            currency: '',
            cloudPosID: '',
            availabilityIcon: '<i class="fas fa-angle-down"></i>',
            iconup: '<i class="fas fa-angle-up"></i>',
            showIcon: false,
            menuType: 1,
            allergenicons: [],
            isCombo: false,
            selectGroup: '',
            selectItem: '',
            comboID: '',
            sname: '',
            sposId: 0,
            squantity: 0,
            samount: 0,
            selectedIcons: [],
            combosSides: [],
            cloudPosCode: [],
            channelSettings: [],
            itemChannelPrice: [],
            modItemChannelPrice: [],
            adminRoles:[]
        }
    },
    watch: {
        postRequirementType() {
            if (this.postRequirementType === 2) {
                this.posid = '';
            }
        }

    },
    computed: {

    },
    mounted() {
        // getting all categories and pushing it to options array for multi-select
        this.getChildItemsDetails();
        this.getCategories();
        this.getModifiers();
        this.getBranches();
        this.getThirdParties();
        this.getManagerStore();
        this.getItems();
        this.getAllergens();
        this.getPosConfigs();
        this.getChannelSettings();

        this.currency = this.pesapalConfig.currency_code ? this.pesapalConfig.currency_code : 'KES'

        //
        if (this.title === '') {
            this.modifiersGrps = [];
            this.$store.commit('addModifierGroups', this.modifierGroups);
        }

    },
    validations() {
        return {
            title: { required },
            price: { required, numeric }
        }
    },
    methods: {
        async getChannelSettings() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.baseUrl}/third-party-setting?merchant_id=${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.channelSettings = response.data.data;
                }
            }).catch((err) => {
                console.log('channel error', err);
            })
        },
        async getPosConfigs() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchant-pos-config/${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.posConfigs = response.data.data
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
            //
        },
        removeSide(key) {
            this.combosSides = this.combosSides.filter((x) => x.key != key)
        },
        addSides() {
            this.combosSides.push({
                'key': Math.random().toString(16).slice(2),
                'name': this.sname,
                'pos_id': this.sposId,
                'quantity': this.squantity,
                'amount': this.samount
            })
        },
        selectIcons(id) {
            const iconCheckBox = document.getElementById(`icon_check_${id}`)
            if (iconCheckBox.checked && !this.selectedIcons.includes(id)) {
                this.selectedIcons.push(id)
            } else {
                const index = this.selectedIcons.indexOf(id)
                if (index > -1) {
                    this.selectedIcons.splice(index, 1);
                }
            }

        },
        searchItems() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getItems();
            }, 600)
        },
        removeItem(item) {
            this.products = this.products.filter((x) => x.id !== item.id)
        },
        async getAllergens() {
            //
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                let url = `${this.dashBaseUrl}/allergen_icons/${this.merchantID}`;
                url = new URL(url);
                if (this.searchStatus != '') {
                    url.searchParams.set('status', '1')
                }
                const res = await this.axios.get(url, config)
                if (res.status === 200) {
                    this.allergenicons = res.data.data

                }
            } catch (error) {
                this.loading = false;
                console.log(error)
            }
        },
        selectItems(item) {
            if (document.getElementById(`item_${item.menu_item.id}`).checked) {
                const productarr = this.products.filter((x) => x.id == item.menu_item.id)
                if (productarr.length == 0) {
                    const prodObj = {
                        'id': item.menu_item.id,
                        'name': item.menu_item.item_name,
                        'price': item.menu_item.price,
                        'image': item.menu_item.image_url
                    }
                    this.products.push(prodObj)
                }
            } else {
                this.products = this.products.filter((x) => x.id !== item.menu_item.id)
            }
            if (this.products.length > 0) {
                this.itemSelected = true;
            } else {
                this.itemSelected = false;
            }
        },
        onItemPageChange(page) {
            this.itemPage = page
            this.getItems();
        },
        async getItems() {
            this.itemLoading = true
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('page', this.itemPage);
            url.searchParams.set('size', this.itemsize);
            url.searchParams.set('merchant_id', this.merchantID);
            if (this.itemSearch) {
                url.searchParams.set('item_name', this.itemSearch);
            }
            url.searchParams.set('standalone', true);
            url.searchParams.set('status', 1);
            url.searchParams.set('list_type', 1);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.itemLoading = false
                    this.items = response.data.data;
                    this.totalPages = Math.ceil(response.data.total / this.itemsize)
                    this.totalRecords = response.data.total
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        },
        searchModifiers() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getModifiers();
            }, 600)
        },
        async createItems(type) {
            this.spinner = type;
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                this.spinner = null;
                return;
            }
            if (this.postRequirementType === 2) {
                if (this.modifierGroupId === '') {
                    this.spinner = null;
                    this.itemTypeErr = 'modifier Group is required';
                    return;
                }
            }
            let modpricesError = false
            const modpricesErrorsData = []
            if(this.appConfigs.allow_multiprice && (this.adminRoles.includes(this.user.role.id) )){
              for (let ch = 0; ch < this.channelSettings.length; ch++) {
                if(this.itemChannelPrice[this.channelSettings[ch].id] == null){
                    toast.error("Please fill all the channels prices")
                    return;
                  }
                  for (let i = 0; i < this.modifiersGrps.length; i++) {
                    for (let x = 0; x < this.modifiersGrps[i].choices.length; x++) {
                      const modItemPrice =  this.modItemChannelPrice[`${this.channelSettings[ch].id}_${this.modifiersGrps[i].id}_${this.modifiersGrps[i].choices[x].id}`]
                        if(modItemPrice == null){
                          if(modpricesErrorsData.filter((x) => x.mod_name == this.modifiersGrps[i].name).length == 0){
                            modpricesErrorsData.push({
                              "mod_name":this.modifiersGrps[i].name
                            })
                          }
                          modpricesError = true
                        }
                    }
                  }
              }
              if(modpricesError){
                let modfierNames = '';
                for(let e =0; e < modpricesErrorsData.length; e++){
                  modfierNames += modpricesErrorsData[e].mod_name + ','
                }
                toast.error(`Please fill Items prices for the following modifier groups ${modfierNames}`)
                this.spinner = null;
                return
              }

            }

            // construct pos id payload
            const itemPesapalPos = [];
            const clouditempos = [];
            for (let x = 0; x < this.posConfigs.length; x++) {
                itemPesapalPos.push({
                    'pos_id': this.posID[this.posConfigs[x].id],
                    'pos_systems': this.posConfigs[x].id
                })

                clouditempos.push({
                    'pos_id': this.cloudPosCode[this.posConfigs[x].id],
                    'pos_systems': this.posConfigs[x].id
                })
            }
            const branchCustomizations = [];
            if (this.branchCust.length > 0) {
                for (let x = 0; x < this.branchCust.length; x++) {
                    branchCustomizations.push({
                        "branch_id": this.branchCust[x],
                        "is_available": this.isAvailable[this.branchCust[x]] === undefined ? false : this.isAvailable[this.branchCust[x]],
                        "is_on_offer": this.onOffer[this.branchCust[x]] === undefined ? false : this.onOffer[this.branchCust[x]],
                        "permanently_out_of_stock": this.stockPeriod[this.branchCust[x]] === undefined ? false : this.stockPeriod[this.branchCust[x]]
                    });

                }
            }
             // format item price per channle
             const itemPricesPerChannel = []
            for (let ch = 0; ch < this.channelSettings.length; ch++) {
                itemPricesPerChannel.push({
                    'channel_id': this.channelSettings[ch].id,
                    'channel_name': this.channelSettings[ch].channel_name,
                    'price': parseFloat(this.itemChannelPrice[this.channelSettings[ch].id])
                })
            }
            // add for foodies(not availlable on db )
            itemPricesPerChannel.push({
                'channel_id': 0,
                'channel_name': 'FOODIE',
                'price': parseFloat(this.itemChannelPrice[0])
            })
            // format modifiers
            const modItemPricesPerChannel = []
            const items = [];
            const modposItems = [];
            for (let i = 0; i < this.modifiersGrps.length; i++) {
                for (let x = 0; x < this.modifiersGrps[i].choices.length; x++) {
                    const choices = this.modifiersGrps[i].choices
                    for (let px = 0; px < this.posConfigs.length; px++) {
                        const modItemPos = this.modPosID[`${choices[x].id}_${this.posConfigs[px].id}_${this.modifiersGrps[i].id}`]
                        modposItems.push({
                            'pos_id': modItemPos,
                            'pos_systems': this.posConfigs[px].id,
                            'mod_group_id': this.modifiersGrps[i].id,
                            'item_id': this.modifiersGrps[i].choices[x].id
                        })
                    }
                    const posID = this.groupItemPos[this.modifiersGrps[i].choices[x].id];
                    items.push({
                        'mod_group_id': this.modifiersGrps[i].id,
                        'item_id': this.modifiersGrps[i].choices[x].id,
                        'pos_id': posID,
                        'price': parseFloat(this.groupItemPrice[this.modifiersGrps[i].choices[x].id])
                    });


                    for (let ch = 0; ch < this.channelSettings.length; ch++) {
                    const modItemPrice =  this.modItemChannelPrice[`${this.channelSettings[ch].id}_${this.modifiersGrps[i].id}_${this.modifiersGrps[i].choices[x].id}`]
                    if( modItemPrice !=null ){
                      modItemPricesPerChannel.push({
                            'channel_id': this.channelSettings[ch].id,
                            'channel_name': this.channelSettings[ch].channel_name,
                            'price': parseFloat(modItemPrice),
                            'item_id': this.modifiersGrps[i].choices[x].id,
                            'mod_id': this.modifiersGrps[i].id
                        })
                    }

                    }
                    // add for foodies(not availlable on db )
                    const foomodItemPrice = this.modItemChannelPrice[`0_${this.modifiersGrps[i].id}_${this.modifiersGrps[i].choices[x].id}`]
                    if (foomodItemPrice !=null){
                      modItemPricesPerChannel.push({
                        'channel_id': 0,
                        'channel_name': 'FOODIE',
                        'price': parseFloat(foomodItemPrice),
                        'item_id': this.modifiersGrps[i].choices[x].id,
                        'mod_id': this.modifiersGrps[i].id
                    })
                    }

                }
            }
            // constructing for modifier items
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const relatedItems = [];
            for (let ri = 0; ri < this.products.length; ri++) {
                relatedItems.push(this.products[ri].id);
            }
            const payload = {
                "merchant_id": parseInt(this.merchantID),
                "menu_item": {
                    "item_name": this.title,
                    "description": this.description,
                    "image_url": this.imageUrl,
                    "price": parseFloat(this.price),
                    "standalone": this.standalone,
                    "related_items": relatedItems,
                    "sides": this.combosSides,
                    "category_ids": this.categoryOptions,
                    "price_perchannel": itemPricesPerChannel,
                    "mod_item_price_perchannel": modItemPricesPerChannel
                },
                "metadata": {
                    "pos_id": parseInt(this.postRequirementType) == 2 ? "0" : this.posid,
                    "item_type": parseInt(this.postRequirementType),
                    "override_group_id": this.modifierGroupId,
                    "combo_select_group": parseInt(this.selectGroupType) == 2 ? this.selectGroup : "",
                    "combo_select_item": parseInt(this.selectGroupType) == 1 ? this.selectItem : "",
                    "combo_id": this.comboID,
                    "is_combo": this.isCombo,
                    "is_vegan": this.vegan ? true : false,
                    "is_vegetarian": this.vegetarion ? true : false,
                    "tax_rate": parseInt(this.taxRate),
                    "is_recommended": this.recomended,
                    "is_alcoholic": this.isAlcoholic,
                    "alcoholic_content": parseInt(this.alcoholiContent),
                    "vat_rate_percentage": parseInt(this.vat),
                    "is_gluten_free": this.gluten ? true : false,
                    "cloud_kitchen_pos_id": this.cloudPosID,
                    "allowed_menus": this.menuType,
                    "allergen_icons": this.selectedIcons
                },
                "modifier_groups": items,
                "menu_item_pos": itemPesapalPos,
                "mod_menu_item_pos": modposItems,
                "branch_customizations": branchCustomizations,
                "cloudkitchen_pos": clouditempos
            }
            // axios methods
            const url = this.baseUrl + "/menu_item";
            this.axios.post(url, payload, config).then((response) => {
                    if (response.status === 201) {
                        toast.success("item created successfully");
                        if (type === 'save&close') {
                            this.$router.push({
                                name: 'items'
                            });
                        } else if (type === 'save&new') {
                            this.title = '';
                            this.description = '';
                            this.imageUrl = '';
                            this.price = '';
                            this.standalone = true;
                            this.categoryOptions = [];
                            this.showIfParent = true;
                            this.postRequirementType = 1;
                            this.posid = '';
                            this.vegan = 0;
                            this.vegetarion = 0;
                            this.taxRate = 0;
                            this.recomended = false;
                            this.modifiersGrps = [];
                            this.isAlcoholic = false;
                            this.alcoholiContent = 0;
                            this.vat = 0;
                            this.gluten = false;
                            this.branchCust = [];
                        } else {
                            this.$router.push({
                                name: 'updateitem',
                                params: response.data.data.id
                            });

                        }

                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    } else {
                        if (error.response) {
                            toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                        } else {
                            toast.error("error creating item")
                        }
                    }

                }).finally(() => this.spinner = null)
        },
        changeIcon() {
            if (!this.showIcon) {
                this.showIcon = true;
                this.availabilityIcon = this.iconup;
            } else {
                this.availabilityIcon = '<i class="fas fa-angle-down"></i>';
                this.showIcon = false;
            }
            //
        },
        checkAll() {
            if (document.getElementById('check_all').checked) {
                for (let i = 0; i < this.stores.length; i++) {
                    this.branchCust.push(this.stores[i].id);
                    this.isAvailable[this.stores[i].id] = true;
                    document.getElementById('store_' + this.stores[i].id).checked = true;
                }
            } else {
                this.branchCust = [];
                for (let i = 0; i < this.stores.length; i++) {
                    this.isAvailable[this.stores[i].id] = false;
                    document.getElementById('store_' + this.stores[i].id).checked = false;
                }
            }

            //
        },
        changeAvailabilityStatusPerStore(storeID) {
            if (document.getElementById('store_' + storeID).checked) {
                this.isAvailable[storeID] = true;
                this.stockPeriod[storeID] = false;
                if (this.branchCust.indexOf(storeID) === -1) {
                    this.branchCust.push(storeID)
                }
                //
            } else {
                this.isAvailable[storeID] = false
                if (this.branchCust.indexOf(storeID) !== -1) {
                    this.branchCust = this.branchCust.filter((x) => x !== storeID);
                }

            }

        },
        assignAvailabilityPeriod(storeId, status) {
            this.stockPeriod[storeId] = status
        },

        checkAvailability(storeID) {
            if (document.getElementById('availability_' + storeID).checked && !document.getElementById('store_' + storeID).checked) {
                document.getElementById('store_' + storeID).checked = true;
                this.stockPeriod[storeID] = false;
            }
        },
        async getThirdParties() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    const channels = response.data.data
                    for (let c = 0; c < channels.length; c++) {
                        this.coptions.push({
                            "value": channels[c].id,
                            "name": channels[c].channel_name
                        })
                    }
                }
            });
        },

        async getManagerStore() {
            //
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/stores/${this.user.id}?merchant_id=${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.managerStores = response.data.data
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
        },
        configureSubProduct(event) {
            if (event.target.checked) {
                this.showIfParent = true;
                // this.standalone = true;
            } else {
                this.showIfParent = false;
                // this.standalone = false;
            }
        },
        addModifierGroup() {
            if (this.modifierGroups.length > 0) {
                const itemsArray = [];
                if (this.$store.state.modifiers.length > 0) {
                    for (let y = 0; y < this.$store.state.modifiers.length; y++) {
                        itemsArray.push(this.$store.state.modifiers[y]);
                    }
                    for (let x = 0; x < this.modifierGroups.length; x++) {
                        const i = itemsArray.findIndex(_element => _element.id === this.modifierGroups[x].id);
                        if (i > -1) {
                            itemsArray[i] = this.modifierGroups[x];
                        } else {
                            itemsArray.push(this.modifierGroups[x]);
                        }
                    }
                    this.$store.commit('addModifierGroups', itemsArray);
                } else {
                    this.$store.commit('addModifierGroups', this.modifierGroups);
                }
                this.modifiersGrps = this.$store.state.modifiers;
                this.getChildItemsDetails()
                // updating v-models with values for
                this.modifierGroups = [];
            }
        },
        getChildItemsDetails() {
            if (this.modifiersGrps.length > 0) {
                for (let x = 0; x < this.modifiersGrps.length; x++) {
                    this.groupId[this.modifiersGrps[x].id] = this.modifiersGrps[x].id;
                    for (let i = 0; i < this.modifiersGrps[x].choices.length; i++) {
                        this.groupItemId[this.modifiersGrps[x].choices[i].id] = this.modifiersGrps[x].choices[i].id;
                        this.groupItemPos[this.modifiersGrps[x].choices[i].id] = this.modifiersGrps[x].choices[i].pos_id;
                        this.groupItemPrice[this.modifiersGrps[x].choices[i].id] = this.modifiersGrps[x].choices[i].price;
                        // autofil poscodes
                        const posConfigs = this.modifiersGrps[x].choices[i].menu_item_pos
                        for (let xi = 0; xi < posConfigs.length; xi++) {
                            this.modPosID[`${this.modifiersGrps[x].choices[i].id}_${posConfigs[xi].pos_systems}_${this.modifiersGrps[x].id}`] = posConfigs[xi].pos_id
                        }
                    }
                }
            }
        },
        removeModifier(id) {
            // console.log('modifiers', this.modifiersGrps);
            this.modifiersGrps = this.modifiersGrps.filter((x) => x.id !== id);
            this.$store.commit('addModifierGroups', this.modifiersGrps);
            return this.modifiersGrps
        },
        showAlcoholic(value) {
            if (value == 1) {
                this.showAcoholic = true;
            } else {
                this.showAcoholic = false;
            }
        },
        checkDietry(dietaryAttribute) {
            if (dietaryAttribute == 'VEGETARIAN' && document.getElementById('vegan').checked) {
                document.getElementById('vegeterian').checked = true;
            } else if (dietaryAttribute == 'VEGAN' && document.getElementById('vegeterian').checked == false) {
                document.getElementById('vegan').checked = false;
            }
        },
        async getCategories() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/menu_category?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        this.categories = response.data.data;
                    }
                    for (let i = 0; i < this.categories.length; i++) {
                        this.options.push({
                            "value": this.categories[i].id,
                            "name": this.categories[i].title
                        })
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        },
        async getModifiers() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/modifier_group?merchant_id=" + this.merchantID + "&query=" + this.search;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.modifiersGroups = response.data.data;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
        },
        async getBranches() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {

                        const stores = response.data.data;
                        this.stores = stores;
                        for (let i = 0; i < stores.length; i++) {
                            this.boptions.push({
                                "value": stores[i].id,
                                "name": stores[i].branch_name
                            })
                        }
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })

        }

    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
